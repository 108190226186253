
/**
 * Create section form handler
 * we handle all the create section here
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters, mapActions, ActionMethod } from "vuex";

import Multiselect from "vue-multiselect";

import DisplayType from "@/components/project/SingleProject/NewSectionTypes/DisplayType.vue";
import InformationType from "@/components/project/SingleProject/NewSectionTypes/InformationType.vue";
import MeasurementType from "@/components/project/SingleProject/NewSectionTypes/MeasurementType.vue";
import CameraType from "@/components/project/SingleProject/NewSectionTypes/CameraType.vue";
import VwaType from "@/components/project/SingleProject/NewSectionTypes/VwaType.vue";
import { NewSectionDto, SectionType } from "@/model/ModelSection";
import Project from "@/model/Project";
import { ActionMessage } from "@/model/Messages/statusMessage";

@Component({
  name: "CreateSectionForm",
  components: {
    DisplayType,
    InformationType,
    MeasurementType,
    CameraType,
    VwaType,
    Multiselect
  },
  computed: {
    ...mapGetters("projects", {
      allSectionTypes: "GetSectionTypes",
      currentProjectDetail: "GetCurrentProject"
    })
  },
  methods: {
    ...mapActions(["getAllDevicesFromBackEnd"]),
    ...mapActions("projects", {
      GetTypesOfSections: "GetAllSectionTypesFromTheBackEnd",
      DetailsOfProject: "DetailsOfProject"
    })
  }
})
export default class CreateSectionForm extends Vue {
  /*----------  Vuex  ----------*/
  private DetailsOfProject!: Function;
  private GetTypesOfSections!: Function;
  getAllDevicesFromBackEnd!: ActionMethod;
  readonly currentProjectDetail!: Project;
  private readonly allSectionTypes!: Array<SectionType>;

  /*----------  Props for the component  ----------*/
  @Prop({ type: Object, default: () => ({}) }) updateStatus!: ActionMessage;

  /*----------  Local data  ----------*/
  localTypes: Array<SectionType> = [];
  newSection: any = {
    type: "",
    override: false,
    order: 1
  };
  sectionDirectionOptions = ["DEFAULT", "OPPOSITE"];
  direction = "DEFAULT";

  /*----------  Vue life cycles  ----------*/
  async created() {
    await this.DetailsOfProject(this.$route.params.id);
    await this.GetTypesOfSections();

    this.sectionDirectionOptions = [
      this.currentProjectDetail.directionNameEnd,
      this.currentProjectDetail.directionNameStart
    ];
    this.direction = this.currentProjectDetail.directionNameEnd;

    this.checkProjectType();
  }
  get isSectionType(): string {
    switch (this.newSection.type.name) {
      case "display":
        return "DisplayType";
      case "measure":
        return "MeasurementType";
      case "camera":
        return "CameraType";
      case "vwa":
        return "VwaType";

      default:
        return "InformationType";
    }
  }

  /*----------  Methods  ----------*/
  createSection({
    title = "",
    kilometrage = "",
    displayOneTypeId = null,
    cbRadioEnabledOne = false,
    cbRadioEnabledTwo = false,
    displayTwoTypeId = null,
    mainboardOneSerial = null,
    mainboardTwoSerial = null,
    backgroundImage = "none.svg",
    radarOnLeftSide = false,
    deviceOneBatteryNumber = Number,
    deviceTwoBatteryNumber = Number,
    deviceOneSolarPanelNumber = Number,
    deviceTwoSolarPanelNumber = Number

  }) {

    const sectionToCreate = new NewSectionDto({
      projectSectionTypeId: this.newSection.type.id,
      title,
      direction: this.direction === this.currentProjectDetail.directionNameEnd ? "DEFAULT" : "OPPOSITE",
      kilometrage,
      description: title,
      backgroundImage,
      displayOneTypeId,
      displayTwoTypeId,
      mainboardOneSerial,
      mainboardTwoSerial,
      cbRadioEnabledOne,
      cbRadioEnabledTwo,
      projectId: this.currentProjectDetail.id,
      ordinalNumber: this.currentProjectDetail.sections.length,
      radarOnLeftSide,
      deviceOneBatteryNumber,
      deviceTwoBatteryNumber,
      deviceOneSolarPanelNumber,
      deviceTwoSolarPanelNumber 
    });

    this.$emit("createNewSection", sectionToCreate);
  }

  resetData() {
    this.newSection = {
      type: ""
    };
  }

  updateDeviceList() {
    this.resetData();
  }

  async checkProjectType() {
    if(this.currentProjectDetail.isVWA) {
      this.localTypes = this.allSectionTypes.filter(sectionType => (sectionType.name == "vwa" || sectionType.name == "info"));

    } else {
      if (this.currentProjectDetail.isFPU || this.currentProjectDetail.isVSU || this.currentProjectDetail.isSCENE) {
        this.localTypes = this.allSectionTypes;
      } else {
        this.localTypes = this.allSectionTypes.filter(sectionType => sectionType.name !== "display");
      }
    }

  }
}
