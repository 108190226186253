












































































































































































































interface LocalBoard {
  id: number;
  name: string;
  serialNumber: string;
}

interface DefaultBoards {
  displayOne: null | Types;
  displayTwo: null | Types;
  boardTwo: null | LocalBoard;
  boardOne: null | LocalBoard;
}

import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import dragable from "vuedraggable";

import Multiselect from "vue-multiselect";
import { mapGetters, mapActions } from "vuex";
import EditSectionModal from "@/components/project/SingleProject/Sections/EditSectionModal.vue";
import KilometrageFromatted from "@/mixins/Project/KilometrageFromatted";
import Section from "@/model/ModelSection";
import { Types } from "@/interface/projectsInterface";
import Project from "@/model/Project";
import UserAccess from "@/model/User/UserAccess";
import SectionToUpdate from "@/model/SingleProject/SectionUpdateDto";

@Component({
  name: "SectionList",
  components: {
    dragable,
    Multiselect,
    EditSectionModal
  },
  computed: {
    ...mapGetters("projects", {
      allSectionTypes: "GetSectionTypes",
      displayTypes: "GetDisplayTypesList",
      currentProject: "GetCurrentProject"
    }),
    ...mapGetters(["getDeviceBySerialNumber", "getUserAccess"])
  },
  methods: {
    ...mapActions(["getAllDevicesFromBackEnd"]),
    ...mapActions("projects", {
      UpdateSection: "UpdateSection",
      UpdateSectionOrdinalNumber: "UpdateSectionOrdinalNumber",
      getIntervalFromTheBackEnd: "GetSectionInterval",
      getSectionDetailsFromBackEnd: "GetSectionDetail",
      refreshTheSectionListFromBackEnd: "GetAllSectionFromTheProject"
    })
  }
})
export default class SectionList extends Mixins(KilometrageFromatted) {
  /*=============================================
  =            Vuex           =
  =============================================*/
  private readonly allSectionTypes!: Array<Types>;
  private readonly displayTypes!: Array<Types>;
  readonly currentProject!: Project;
  private readonly getDeviceBySerialNumber!: Function;
  readonly getUserAccess!: UserAccess;

  private UpdateSection!: Function;
  private UpdateSectionOrdinalNumber!: Function;
  private getIntervalFromTheBackEnd!: Function;
  private getSectionDetailsFromBackEnd!: Function;
  refreshTheSectionListFromBackEnd!: Function;
  private getAllDevicesFromBackEnd!: Function;

  /*----------  Props  ----------*/
  @Prop({ type: Array, default: () => [] }) allSections!: Array<Section>;

  /*----------  Local data  ----------*/

  sectionUpdateError: null | object = null;
  sectionToEdit: null | Section = null;
  defaultDisplayAndBoards: DefaultBoards = {
    displayOne: null,
    displayTwo: null,
    boardTwo: null,
    boardOne: null
  };
  scheduleActive = [];
  allSectionsLocal: Array<Section> = [];
  drag = false;
  fields: Array<string> = ["sort", "order", "id", "type", "position", "description", "actions"];
  totalOposite = 0;
  totaldefault = 0;

  isSectionUpdating = false;

  /*----------  Watch  ----------*/
  @Watch("allSections")
  handleAllSection(newSectionValue: Array<Section>) {
    this.allSectionsLocal = newSectionValue;
    this.setLocalSections();
  }

  /*----------  Computed   ----------*/
  get dragOptions() {
    return {
      animation: 150,
      group: "description",
      disabled: false,
      ghostClass: "ghost",
      handle: ".handle",
      filter: ".notSortableItem"
    };
  }

  setLocalSections() {
    this.allSectionsLocal = this.allSections;
    if (this.getUserAccess.Access.VIEW.project.sectionListTab.allTableHeaders) {
      if (this.currentProject.isRZP) {
        this.totalOposite = this.allSectionsLocal.reduce((acc: number, curent: Section) => {
          if (curent.direction === "OPPOSITE") {
            acc++;
          }
          return acc;
        }, 0);
        this.totaldefault = this.allSectionsLocal.reduce((acc: number, curent: Section) => {
          if (curent.direction === "DEFAULT") {
            acc++;
          }
          return acc;
        }, 0);

        !this.fields.includes("Direction") ? this.fields.push("Direction") : "";
      }
    } else {
      this.fields = ["order", "id", "type", "position", "description"];
    }
  }

  async handleDragEnd() {
    let isRZP = this.currentProject.isRZP;
    let totalSections = this.allSectionsLocal.length;

    let promises = this.allSectionsLocal.map(async (item, index) => {
      item.ordinalNumber = isRZP ? totalSections - index : index;
      const sectionFormated = new SectionToUpdate(item).FormatToUpdate();

      await this.UpdateSectionOrdinalNumber(sectionFormated);
    });
    await Promise.all(promises);
    await this.refreshTheSectionListFromBackEnd(this.currentProject.id);
  }

  async openEditForm(item: Section) {

    console.log("EDit Form");

    this.getAllDevicesFromBackEnd().then(() => {
      this.sectionUpdateError = null;
      //@ts-ignore
      item.type.label = this.$t(`project.sectionOptions.${item.type?.name}`);

      this.$bvModal.show("updateSectionInformation");

      this.sectionToEdit = item;
      this.defaultDisplayAndBoards.displayOne = item.displayOneType
        ? this.displayTypes.filter((device) => device.id == item.displayOneType?.id)[0]
        : null;

      this.defaultDisplayAndBoards.displayTwo = item.displayTwoType
        ? this.displayTypes.filter((device) => device.id == item.displayTwoType?.id)[0]
        : null;
      this.defaultDisplayAndBoards.boardOne = this.getDeviceBySerialNumber(item.boardOne);
      this.defaultDisplayAndBoards.boardTwo = this.getDeviceBySerialNumber(item.boardTwo);
      // Get Interval
      if (item.type?.name === "display") {
        this.getSectionDetailsFromBackEnd(item.id);
        // this.getIntervalFromTheBackEnd(item.id);
      }
    });
  }

  updateDeviceList() {
    this.refreshTheSectionListFromBackEnd(this.currentProject.id);
    this.$bvModal.hide("updateSectionInformation");
    this.sectionToEdit = null;
    this.defaultDisplayAndBoards = {
      displayOne: null,
      displayTwo: null,
      boardTwo: null,
      boardOne: null
    };
  }

  async updateSectionInformation() {
    this.$emit("UpdateStatus", null);
    try {
      this.sectionUpdateError = null;
      this.isSectionUpdating = true;
      if (this.sectionToEdit) {
        this.sectionToEdit.displayOneType = this.defaultDisplayAndBoards.displayOne
          ? this.defaultDisplayAndBoards.displayOne
          : this.sectionToEdit.displayOneType;
        this.sectionToEdit.displayTwoType = this.defaultDisplayAndBoards.displayTwo
          ? this.defaultDisplayAndBoards.displayTwo
          : this.sectionToEdit.displayTwoType;
        this.sectionToEdit.boardOne = this.defaultDisplayAndBoards.boardOne
          ? this.defaultDisplayAndBoards.boardOne.serialNumber
          : "";
        this.sectionToEdit.boardTwo = this.defaultDisplayAndBoards.boardTwo
          ? this.defaultDisplayAndBoards.boardTwo.serialNumber
          : "";
        this.sectionToEdit.projectId = this.currentProject.id;
        const sectionFormatted = new SectionToUpdate(this.sectionToEdit).FormatToUpdate();
        const res = await this.UpdateSection(sectionFormatted);
        if (res.status === 200) {
          this.$emit("UpdateStatus", {
            class: "success",
            msg: this.$t("projects.singleProjectView.statusMsg.updateSectionSuccess")
          });
          this.updateDeviceList();
        } else {
          const translation = this.$t(res.label).toString();
          const fallbackOrTranslationCheck = res.label === translation ? res.message : translation;
          this.sectionUpdateError = {
            class: "danger",
            msg: fallbackOrTranslationCheck
          };
        }
      }
    } catch (error) {
      this.$emit("UpdateStatus", {
        class: "danger",
        msg: error
      });
    } finally {
      this.isSectionUpdating = false;
    }
  }

  checkProjectTypeAndTheSectionType(item: Section) {
    let isProjectMWSA = this.currentProject.type?.id !== 2 && this.currentProject.type?.id !== 3;
    let isSectionInformation = item.type?.name === "info";
    return isProjectMWSA && !isSectionInformation;
  }

  async HandleUpdateVisibilityState(section: Section) {
    this.sectionUpdateError = null;
    section.visible = !section.visible;
    section.projectId = this.currentProject.id;
    const sectionFormated = new SectionToUpdate(section).FormatToUpdate();
    const res = await this.UpdateSection(sectionFormated);
    if (res.status === 200) {
      this.$emit("UpdateStatus", {
        class: "success",
        msg: this.$t("projects.singleProjectView.statusMsg.updateSectionSuccess")
      });
      this.updateDeviceList();
    } else {
      const translation = this.$t(res.label).toString();
      const fallbackOrTranslationCheck = res.label === translation ? res.message : translation;
      this.sectionUpdateError = {
        class: "danger",
        msg: fallbackOrTranslationCheck
      };
    }
  }
}
