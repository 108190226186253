
/***
 * Edit the section in the project.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [✔] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import DisplayModalInterval from "@/components/project/SingleProject/Schematic/Interval/DisplayModalInterval.vue";
import FpuSettings from "@/components/project/SingleProject/Schematic/FpuSettings.vue";
import CwuSettings from "@/components/project/SingleProject/Schematic/CwuSettings.vue";
import SectionImagesSelection from "@/components/project/SingleProject/NewSectionTypes/SectionImagesSelection.vue";
import Section, { SectionType } from "@/model/ModelSection";
import { DisplayTypes } from "@/interface/projectsInterface";
import Project from "@/model/Project";
import { Device } from "@/interface/DeviceInterface";
import { ActionMessage } from "@/model/Messages/statusMessage";

@Component({
  name: "EditSectionModal",
  components: {
    DisplayModalInterval,
    Multiselect,
    FpuSettings,
    CwuSettings,
    SectionImagesSelection
  },
  computed: {
    ...mapGetters(["getAllNewAndBookedDevices", "getDeviceBySerialNumber", "getAllBookedAndInFieldDevices"]),
    ...mapGetters("projects", {
      allSectionTypes: "GetSectionTypes",
      displayTypes: "GetDisplayTypesList",
      currentProjectDetail: "GetCurrentProject"
    })
  },
  methods: {
    ...mapActions("projects", {
      GetSectionDetail: "GetSectionDetail"
    })
  }
})
export default class EditSectionModal extends Vue {
  @Prop({ type: Object, default: () => ({}) }) sectionToEdit!: Section;
  @Prop({ type: Object, default: () => ({}) }) defaultDisplayAndBoards!: any;
  @Prop({ type: Object, default: () => ({}) }) sectionUpdateError!: null | ActionMessage;
  @Prop({ type: Boolean, default: false }) updatingSection!: boolean;

  /*----------  Vuex  ----------*/
  readonly allSectionTypes!: Array<SectionType>;
  readonly displayTypes!: Array<DisplayTypes>;
  readonly currentProjectDetail!: Project;
  private readonly getAllBookedAndInFieldDevices!: Array<Device>;

  private getAllNewAndBookedDevices!: Function;
  private getDeviceBySerialNumber!: Function;
  private GetSectionDetail!: Function;
  /*----------  Local data  ----------*/
  localOptions: Array<any> = [];
  sectionDirectionOptions = ["DEFAULT", "OPPOSITE"];
  localDirectionValue = "DEFAULT";
  LocalError: null | ActionMessage = null;
  batteryNumberOptions: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  solarPanelNumberOptions: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  /*----------  Vue lifecycles  ----------*/

  async created() {
    this.sectionDirectionOptions = [
      this.currentProjectDetail.directionNameEnd,
      this.currentProjectDetail.directionNameStart
    ];
    this.localDirectionValue =
      this.sectionToEdit.direction === "DEFAULT"
        ? this.currentProjectDetail.directionNameEnd
        : this.currentProjectDetail.directionNameStart;
  }

  mounted() {
    this.setLocalOptions();
    this.GetSectionDetail(this.sectionToEdit.id);
  }

  /*----------  Computed  ----------*/
  get sectionType(): string {
    return this.sectionToEdit.type ? this.sectionToEdit.type.name : "info";
  }

  get checkSerialNumbers() {
    if (this.defaultDisplayAndBoards.boardOne && this.defaultDisplayAndBoards.boardTwo) {
      let boardOneSerial = this.defaultDisplayAndBoards.boardOne.serialNumber;
      let boardTwoSerial = this.defaultDisplayAndBoards.boardTwo.serialNumber;
      return boardOneSerial === boardTwoSerial;
    } else return false;
  }

  get positionHaveSemCol(): boolean {
    return this.sectionToEdit.kilometrage.toString().indexOf(";") >= 0;
  }

  /*----------  Methods  ----------*/
  checkProjectType() {
    if (this.currentProjectDetail.isFPU || this.currentProjectDetail.isVSU) {
      return this.allSectionTypes.filter((item) => item.name !== "measure");
    } else {
      return this.allSectionTypes;
    }
  }

  setLocalOptions() {
    let optLocal: Array<Device> = this.getAllNewAndBookedDevices();

    if (this.sectionToEdit.type?.name !== "display" && this.sectionToEdit.type?.name !== "vwa") {
      optLocal = [...optLocal, ...this.getAllBookedAndInFieldDevices];
    }
    if (this.defaultDisplayAndBoards.boardOne) {
      optLocal.unshift(this.getDeviceBySerialNumber(this.defaultDisplayAndBoards.boardOne.serialNumber));
    }
    if (this.defaultDisplayAndBoards.boardTwo) {
      optLocal.unshift(this.getDeviceBySerialNumber(this.defaultDisplayAndBoards.boardTwo.serialNumber));
    }
    this.localOptions = Array.from(new Set(optLocal));
  }

  HandleImgChange(imageName: string) {
    this.sectionToEdit.backgroundImage = imageName;
  }

  updateDirection() {
    this.sectionToEdit.direction =
      this.localDirectionValue === this.currentProjectDetail.directionNameEnd ? "DEFAULT" : "OPPOSITE";
  }

  handleUpdateSectionInformation() {
    if (!this.positionHaveSemCol) {
      this.$emit("updateSectionInformation");
    } else {
      this.LocalError = {
        class: "danger",
        msg: this.$t("utils.feedback.semiCol").toString()
      };
      return;
    }
  }
}
